<template>
  <div class="container">
    <quote v-for="quote in quotes" v-bind:key="quote.uid" v-bind:quote="quote">
    </quote>
    <div class="text-center" v-if="status === 'load'">
      <div
        class="spinner-border mt-5"
        role="status"
        style="width: 3rem; height: 3rem"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="alert alert-danger" v-if="status === 'error'" role="alert">
      {{ error }}
    </div>
  </div>
</template>

<script>
import Quote from "@/components/Quote";

export default {
  name: "Quotes",
  components: {
    Quote,
  },
  data: function () {
    return {
      status: "",
      error: null,
      quotes: [],
    };
  },
  methods: {
    getQuotes() {
      if (this.status === "load") return;
      this.status = "load";

      this.apis.quotes.getQuotes().then((result) => {
        this.quotes.push(...result);
        this.status = "ready";
      });
    },
  },
  mounted() {
    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 1000;
      if (bottomOfWindow) {
        this.getQuotes();
      }
    };
    this.getQuotes();
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}
</style>
