<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="d-flex justify-content-between mb-2">
        <a v-bind:href="quote.url">#{{ quote.uid }}</a>
        <span>{{ formatDate() }}</span>
      </div>
      <p class="quote-body">{{ quote.body }}</p>
      <img v-if="quote.comics_url" v-bind:src="quote.comics_url" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Quote",
  props: ["quote"],
  methods: {
    formatDate() {
      let date = new Date(this.quote.date);
      return date.toLocaleString("ru-RU", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.quote-body {
  white-space: pre-wrap;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
</style>
